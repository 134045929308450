<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加商品</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区域 -->
    <el-card class="box-card">
      <!-- 提示区域 -->
      <el-alert title="添加商品信息" type="info" center show-icon :closable="false"></el-alert>
      <el-steps :space="200" :active="activeIndex - 0" finish-status="success" align-center>
        <el-step title="基本信息"></el-step>
        <el-step title="商品参数"></el-step>
        <el-step title="商品属性"></el-step>
        <el-step title="商品图片"></el-step>
        <el-step title="商品内容"></el-step>
        <el-step title="完成"></el-step>
      </el-steps>
      <!-- tab栏区域 -->
      <el-form :model="addGoodsForm" :rules="goodsFormRules" ref="addGoodsFormRef" label-width="80px" label-position="top">
        <el-tabs v-model="activeIndex" tab-position="left" :before-leave="beforeTabLeave" @tab-click="tabClick">
          <el-tab-pane label="基本信息" name="0">
            <el-form-item label="商品名称" prop="goods_name">
              <el-input v-model.trim="addGoodsForm.goods_name"></el-input>
            </el-form-item>
            <el-form-item label="商品价格" prop="goods_price">
              <el-input v-model="addGoodsForm.goods_price" type="number"></el-input>
            </el-form-item>
            <el-form-item label="商品重量" prop="goods_weight">
              <el-input v-model="addGoodsForm.goods_weight" type="number"></el-input>
            </el-form-item>
            <el-form-item label="商品数量" prop="goods_number">
              <el-input v-model="addGoodsForm.goods_number" type="number"></el-input>
            </el-form-item>
              <el-form-item label="商品分类" prop="goods_cat">
                <!-- 级联选择器区域 -->
                <!-- options：可选项数据源 -->
                <!-- props：数据配置选项 -->
                <el-cascader
                v-model="addGoodsForm.goods_cat"
                :options="catList"
                :props="catProp"
                clearable
                @change="catChanged">
                </el-cascader>
              </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="商品参数" name="1">
            <el-form-item :label="item1.attr_name" v-for="item1 in manyParamsList" :key="item1.attr_id">
              <el-checkbox-group v-model="item1.attr_vals">
                <el-checkbox :label="item2" v-for="(item2, i) in item1.attr_vals" :key="i" border></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="商品属性" name="2">
            <el-form-item :label="item.attr_name" v-for="item in onlyParamsList" :key="item.attr_id">
              <el-input v-model.trim="item.attr_vals"></el-input>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="商品图片" name="3">
            <el-upload
            :action="uploadURL"
            :headers="headersObj"
            :on-success="success"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            list-type="picture">
             <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-tab-pane>
          <el-tab-pane label="商品内容" name="4">
            <quill-editor v-model="addGoodsForm.goods_introduce"></quill-editor>
            <el-button type="primary" @click="addGoods" class="btn-add">添加商品</el-button>
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </el-card>
    <!-- 图片预览区域 -->
    <el-dialog title="图片预览" :visible.sync="previewVisible" width="50%">
      <img :src="previewPath" class="previewImg">
    </el-dialog>
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  data () {
    return {
      // 图片上传API接口
      uploadURL: 'http://127.0.0.1:8888/api/private/v1/upload',
      // ajax请求头设置
      headersObj: {
        Authorization: window.sessionStorage.getItem('token')
      },
      // 图片预览的路径
      previewPath: '',
      // 控制图片预览显示与隐藏
      previewVisible: false,
      // tab页签激活的名称
      activeIndex: '0',
      // 添加商品的表单数据
      addGoodsForm: {
        goods_name: '',
        goods_price: 0,
        goods_weight: 0,
        goods_number: 0,
        goods_cat: [], // 商品分类
        pics: [], // 商品图片路径
        goods_introduce: '' // 商品详情
      },
      // 商品分类列表
      catList: [],
      // 级联选择器配置选项
      catProp: {
        // 鼠标悬停展开次级分类
        expandTrigger: 'hover',
        // 只能选择最后一级选项
        checkStrictly: false,
        label: 'cat_name',
        value: 'cat_id',
        children: 'children'
      },
      // 动态参数列表
      manyParamsList: [],
      // 静态属性列表
      onlyParamsList: [],
      // 填写商品表单预校验规则
      goodsFormRules: {
        goods_name: [
          { required: true, message: '请输入商品名称', trigger: 'blur' }
        ],
        goods_price: [
          { required: true, message: '请输入商品价格', trigger: 'blur' }
        ],
        goods_weight: [
          { required: true, message: '请输入商品重量', trigger: 'blur' }
        ],
        goods_number: [
          { required: true, message: '请输入商品数量', trigger: 'blur' }
        ],
        goods_cat: [
          { required: true, message: '请选择商品分类', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getCatList()
  },
  methods: {
    // 获取分类列表
    async getCatList () {
      const { data: res } = await this.$http.get('categories')
      console.log(res)
      if (res.meta.status !== 200) {
        return this.$message({
          type: 'error',
          message: '获取分类列表失败',
          center: true,
          duration: 1000
        })
      }
      this.catList = res.data
    },
    // 级联选择器节点变化时，即商品分类选项变化时触发的事件
    catChanged () {
      console.log(this.addGoodsForm.goods_cat)
      // 只能选择三级分类，否则清空选择
      if (this.addGoodsForm.goods_cat.length !== 3) {
        this.addGoodsForm.goods_cat = []
      }
    },
    // tab页签即将离开之前触发的事件
    beforeTabLeave (activeName, oldActiveName) {
      // activeName：即将进入的标签名；oldActiveName：即将离开的标签名
      // 如果是添加商品的第一步骤且没有选择商品的三级分类，强制阻止tab切换
      if (oldActiveName === '0' && this.addGoodsForm.goods_cat.length !== 3) {
        this.$message({
          type: 'error',
          message: '请先选择商品分类！',
          center: true,
          duration: 1000
        })
        return false // 返回值为false，表示强制阻止
      }
    },
    // 获取选中分类的动态参数/静态属性
    async getParamsData (sel) {
      const { data: res } = await this.$http.get(`categories/${this.getCatId}/attributes`, {
        params: {
          sel: sel
        }
      })
      console.log(res)
      if (res.meta.status !== 200) {
        return this.$message({
          type: 'error',
          message: sel === 'many' ? '获取动态参数失败' : '获取静态属性失败',
          center: true,
          duration: 1000
        })
      }
      if (sel === 'many') {
        res.data.forEach(item => {
          item.attr_vals = item.attr_vals ? item.attr_vals.split(',') : []
        })
        this.manyParamsList = res.data
      } else {
        this.onlyParamsList = res.data
      }
    },
    // 点击选中tab页签时触发的事件
    tabClick () {
      if (this.activeIndex === '1') {
        // 获取选中分类的动态参数
        this.getParamsData('many')
        console.log(this.manyParamsList)
      } else if (this.activeIndex === '2') {
        // 获取选中分类的静态属性
        this.getParamsData('only')
      }
    },
    // 图片上传成功触发的事件
    success (response) {
      // 1.获得图片路径，保存为一个图片路径对象
      const picInfo = {
        pic: response.data.tmp_path
      }
      // 2.把该图片路径对象添加到pics数组中
      this.addGoodsForm.pics.push(picInfo)
    },
    // 处理图片预览效果
    handlePreview (file) {
      console.log('预览', file)
      this.previewPath = 'http://127.0.0.1:8888/' + file.response.data.tmp_path
      this.previewVisible = true
    },
    // 处理移除图片的操作
    handleRemove (file) {
      // 1. 获得图片路径，保存为一个图片路径对象
      const picInfo = {
        pic: file.response.data.tmp_path
      }
      // 2.获得该图片路径对象在pics数组中的索引
      const i = this.addGoodsForm.pics.forEach(item => {
        if (item.pic === picInfo.pic) return true
      })
      // 3.通过索引删除该图片对象路径
      this.addGoodsForm.pics.splice(i, 1)
      console.log(this.addGoodsForm.pics)
    },
    // 点击按钮，添加商品
    addGoods () {
      this.$refs.addGoodsFormRef.validate(async (valid) => {
        if (!valid) {
          return this.$message({
            type: 'error',
            message: '请填写必要的表单项！',
            center: true,
            duration: 1000
          })
        }
        // 执行添加商品的业务逻辑
        // 利用lodash实现对象深拷贝
        const formData = _.cloneDeep(this.addGoodsForm)
        formData.goods_cat = formData.goods_cat.join(',')
        formData.attrs = []
        // 动态参数
        this.manyParamsList.forEach(item => {
          const attrObj = {
            attr_id: item.attr_id,
            attr_value: item.attr_vals.join(',')
          }
          formData.attrs.push(attrObj)
        })
        // 静态属性
        this.onlyParamsList.forEach(item => {
          const attrObj = {
            attr_id: item.attr_id,
            attr_value: item.attr_vals
          }
          formData.attrs.push(attrObj)
        })
        console.log(formData)
        const { data: res } = await this.$http.post('goods', formData)
        console.log(res)
        if (res.meta.status !== 201) {
          return this.$message({
            type: 'error',
            message: res.meta.msg || '添加商品失败',
            center: true,
            duration: 1000
          })
        }
        this.$message({
          type: 'success',
          message: '添加商品成功',
          center: true,
          duration: 1000
        })
        // 跳转回商品列表页面
        this.$router.push('/goods')
      })
    }
  },
  computed: {
    // 获取选中的三级分类id
    getCatId () {
      if (this.addGoodsForm.goods_cat.length === 3) {
        return this.addGoodsForm.goods_cat[2]
      }
      return null
    }
  }
}
</script>
<style lang="less" scoped>
  .el-checkbox{
    margin: 0 10px 0 0 !important;
  }
  .previewImg{
    width: 100%;
  }
  .btn-add{
    margin-top: 16px;
  }
</style>
