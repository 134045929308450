<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区域 -->
    <el-card class="box-card">
      <!-- 搜索和添加商品区域 -->
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input placeholder="请输入搜索内容" clearable v-model.trim="queryInfo.query" @clear="getGoodsList">
            <el-button slot="append" icon="el-icon-search" @click="queryInfo.query?getGoodsList():''"></el-button>
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="goAddGoods('/goods/add')">添加商品</el-button>
        </el-col>
      </el-row>
      <!-- 表格区域 -->
      <el-table :data="goodsList" border style="width: 100%" :stripe="true">
        <el-table-column type="index" label="#" align="center"></el-table-column>
        <el-table-column prop="goods_name" label="商品名称" align="center"></el-table-column>
        <el-table-column prop="goods_price" label="商品价格(元)" align="center" width="110px"></el-table-column>
        <el-table-column prop="goods_weight" label="商品重量" align="center" width="100px"></el-table-column>
        <el-table-column label="创建时间" align="center" width="170px">
          <template slot-scope="scope">
            {{scope.row.add_time | dateFormat}}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="140px">
          <template slot-scope="scope">
            <el-tooltip content="修改" placement="top" :enterable="false">
              <el-button type="primary" icon="el-icon-edit" size="mini" ></el-button>
            </el-tooltip>
            <el-tooltip content="删除" placement="top" :enterable="false">
              <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteGoods(scope.row.goods_id)"></el-button>
             </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[1, 2, 5, 10, 15, 20]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background>
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
export default {
  data () {
    return {
      queryInfo: {
        query: '',
        pagenum: 1,
        pagesize: 2
      },
      goodsList: [],
      total: 0,
      goods_state_result: ['未通过', '审核中', '已审核'],
      dialogVisible: false
    }
  },
  created () {
    this.getGoodsList()
  },
  methods: {
    // 获取商品列表
    async getGoodsList () {
      const { data: res } = await this.$http.get('goods', { params: this.queryInfo })
      console.log(res)
      if (res.meta.status !== 200) {
        return this.$message({
          type: 'error',
          message: '获取商品列表失败',
          center: true,
          duration: 1000
        })
      }
      // 获取商品列表成功
      this.goodsList = res.data.goods
      this.total = res.data.total
    },
    handleSizeChange (newPagesize) {
      // 改变每页显示的条目数
      this.queryInfo.pagesize = newPagesize
      // 更新商品列表
      this.getGoodsList()
    },
    handleCurrentChange (newPagenum) {
      // 改变页码值
      this.queryInfo.pagenum = newPagenum
      // 更新商品列表
      this.getGoodsList()
    },
    // 删除商品
    async deleteGoods (goodsId) {
      const result = await this.$confirm('此操作将永久删除该商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      // 确定删除返回confirm，取消删除返回cancel
      if (result !== 'confirm') {
        return this.$message({
          type: 'info',
          message: '已取消删除操作',
          center: true,
          duration: 1000
        })
      }
      const { data: res } = await this.$http.delete(`goods/${goodsId}`)
      console.log(res)
      if (res.meta.status !== 200) {
        return this.$message({
          type: 'error',
          message: '删除商品失败',
          center: true,
          duration: 1000
        })
      }
      this.$message({
        type: 'success',
        message: '删除商品成功',
        center: true,
        duration: 1000
      })
      // 更新商品列表
      this.getGoodsList()
    },
    // 跳转到添加商品页面
    goAddGoods (url) {
      this.$router.push(url)
    }
  }
}
</script>
<style lang="less" scoped>
</style>
